import React from 'react'

const HeaderColumn = ({ratio, columnName}) => {
    return (
        <div style={{ width: `${ratio}%`, float: 'left', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>{columnName}</div>
        </div>
    )
}

const Header = (props) => {
    let columnNameList = props.columnNameList
    let LastColumn = props.LastColumn
    let headerColumnList = []
    let ratio =  (!LastColumn ? (100 / columnNameList.length).toFixed(4) : (100 / (columnNameList.length + 1)))
    columnNameList.forEach((columnName, idx) => {
        headerColumnList.push(
            <HeaderColumn key={idx} ratio={ratio} columnName={columnName}/>
        )
    })
    if(LastColumn){
        headerColumnList.push(
            <HeaderColumn 
                key={columnNameList.length} 
                ratio={ratio} 
                columnName={LastColumn}
            />
        )
    }
    return (
        <div 
            style={{ 
                width: '100%', 
                height: '40px', 
                backgroundColor: 'rgba(80,80,80,0.8)', 
                color: 'rgba(255,255,255,1)',
                borderTopLeftRadius:'20px',
                borderTopRightRadius:'20px'
            }}
        >
            {headerColumnList}
        </div>
    )
}

export default Header