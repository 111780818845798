import React from 'react'
import Title from '../title/index'
import Header from '../table-header/index'
import Body from '../table-body/index'

const FirmwareList = ({changeActiveVersion, deviceCode, firmwareList, stateChangeHandler, firmwareUpload, deleteHandler, deviceCodeList}) => {
    let oldFile = firmwareList.temp.uploadFile
    firmwareList = JSON.parse(JSON.stringify(firmwareList))
    firmwareList.temp.uploadFile = oldFile
    let name = firmwareList.title
    let columnNameList = firmwareList.columnNameList
    let itemsList = []
    let rowStyleList = []
    firmwareList.itemsList.forEach((items, idx) => {
        if(items[3] === deviceCode){
            itemsList.push(items)
        }
    })
    itemsList.forEach((items, idx) => {
        console.log(`device code : ${items[3]}`)
        if(items[2] === 'O'){
            rowStyleList[idx] = { 
                color: 'rgba(255,255,255,1)',
                height:'40px',
                fontWeight:'800',
                backgroundColor:'rgba(200,200,200,1)'
            }
        }
        if(items[2] === 'X'){
            rowStyleList[idx] = { 
                color: 'rgba(255,255,255,1)',
                height:'40px'
            }
        }
    })
    let codeOptions = [(<option key={`code-0`} value="None">None</option>)]
    let codeOptionsForAdd = [(<option key={`code-0`} value="None">None</option>)]
    deviceCodeList.forEach((deviceCode, idx) => {
        let code = deviceCode[0]
        codeOptions.push(<option key={`code-${idx + 1}`} value={code}>{code}</option>)
        codeOptionsForAdd.push(<option key={`code-${idx + 1}`} value={code}>{code}</option>)
    })
    return (
        <div style={{width:'100%', display:'inline-block', marginBottom:'18px'}}>
            <Title name={name}/>
            <div style={{color:'rgba(80,80,80,0.8)', fontSize:'14px', fontWeight:'800', padding:'4px'}}>
                장비 코드 선택
                <select
                    style={{marginLeft:'8px'}}
                    value={deviceCode}
                    onChange={
                        (e) => {
                            let new_deviceCode = e.target.value
                            firmwareList.deviceCode = new_deviceCode
                            stateChangeHandler({firmwareList})
                        }
                    }
                >
                    {codeOptions}
                </select>
            </div>
            <Header columnNameList={columnNameList}/>
            {
                deviceCode === 'None' || itemsList.length === 0?
                <div 
                    style={{
                        width:'100%', 
                        height:'40px', 
                        display:'flex', 
                        justifyContent:'center', 
                        alignItems:'center',
                        color:'rgba(80,80,80,0.8)'
                    }}
                >
                    <div>장비 코드를 선택하세요</div>
                </div>
                :
                <Body 
                itemsList={itemsList}
                rowStyleList={rowStyleList}
                stateChangeHandler={stateChangeHandler}
                deleteHandler={
                    (idx) => {
                        if(itemsList[idx][2] === 'O'){
                            alert('활성화된 펌웨어는 삭제 할 수 없습니다.')
                            return
                        }
                        deleteHandler({
                            type:'firmware',
                            id:itemsList[idx][0]
                        })
                    }
                }
                onRowClick={
                    (idx) => {
                        let oldVersion = undefined
                        let newVersion = itemsList[idx][0]
                        itemsList.forEach((items, idx) => {
                            if(items[3] === deviceCode && items[2] === 'O'){
                                oldVersion = items[0]
                            }
                        })
                        if(oldVersion === newVersion) return
                        if(!oldVersion) return
                        if(!newVersion) return
                        let answer = window.confirm('펌웨어 활성을 변경 하시겠습니까?')
                        console.log(answer)
                        if(answer){
                            changeActiveVersion({oldVersion, newVersion})
                        }else{
                            alert('폄웨어 활성 변경이 취소되었습니다.')
                        }
                        /*
                        let newItemsList = []
                        itemsList.forEach((items, index) => {
                            if(idx !== index){
                                items[2] = 'X'
                                newItemsList.push(items)
                            }
                            else if(idx === index){
                                items[2] = '0'
                                newItemsList.push(items)
                            }
                        })
                        firmwareList.itemsList = newItemsList
                        stateChangeHandler({firmwareList})
                        */
                    }
                }
                />
            }
            <div
                style={{
                    width:'calc(100% - 40px)',
                    height:'40px',
                    border:'1px solid rgba(80,80,80,0.8)',
                    position:'fixed',
                    bottom:'20px',
                    borderRadius:'10px'
                }}
            >
                <div style={{width:'25%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'100%', paddingLeft:'20px'}}>
                        <input 
                        type="file" 
                        accept=".dfu"
                        style={{width:'80%'}}
                        value={firmwareList.temp.filename || ''}
                        onChange={
                            (e) => {
                                let uploadFile = e.target.files[0]
                                let filename = e.target.value
                                firmwareList.temp.uploadFile = uploadFile
                                firmwareList.temp.filename = filename
                                stateChangeHandler({firmwareList})
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'25%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <input 
                        type="text"
                        placeholder="펌웨어에 대한 간단한 설명" 
                        style={{padding:'4px', width:'200px'}}
                        value={firmwareList.temp.description || ''}
                        onChange={
                            (e) => {
                                let description = e.target.value
                                firmwareList.temp.description = description
                                stateChangeHandler({firmwareList})
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'25%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{color:'rgba(80,80,80,0.8)', fontSize:'14px', fontWeight:'800', padding:'4px'}}>
                        장비 코드
                        <select
                            style={{marginLeft:'8px'}}
                            value={firmwareList.temp.deviceCode}
                            onChange={
                                (e) => {
                                    let deviceCode = e.target.value
                                    firmwareList.temp.deviceCode = deviceCode
                                    stateChangeHandler({firmwareList})
                                }
                            }
                        >
                            {codeOptionsForAdd}
                        </select>
                    </div>
                </div>
                <div style={{width:'25%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <button 
                            style={{padding:'0 8px'}}
                            onClick={
                                (e) => {
                                    firmwareUpload()
                                }
                            }
                        >
                            업로드
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirmwareList