import React from 'react'
import Title from '../title/index'
import Header from '../table-header/index'
import Body from '../table-body/index'

const DeviceList = ({ deviceList, stateChangeHandler, deleteHandler, pageChangeHandler, deviceSearchChangeHander, requestSearch, deleteSelectAll, currentPageChangeHandler, updateRequest }) => {
    let name = deviceList.title
    let columnNameList = deviceList.columnNameList
    let tempItemsList = deviceList.itemsList
    let totalPage = Math.ceil(deviceList.totalSize / 10)
    let pageOption = []
    for (let i = 1; i <= totalPage; i++) {
        pageOption.push(
            <option value={`${i}`} key={`devicelist-page-${i}`}>{i} Page</option>
        )
    }
    let itemsList = []
    tempItemsList.forEach((i, idx) => {
        itemsList[idx] = JSON.parse(JSON.stringify(i))
        if (itemsList[idx][0].length > 20) {
            itemsList[idx][0] = itemsList[idx][0].slice(0, 8) + ' . . . . ' + itemsList[idx][0].slice(itemsList[idx][0].length - 8, itemsList[idx][0].length)
        }
        if (itemsList[idx][1].length > 20) {
            itemsList[idx][1] = itemsList[idx][1].slice(0, 8) + ' . . . . ' + itemsList[idx][1].slice(itemsList[idx][1].length - 8, itemsList[idx][1].length)
        }
        itemsList[idx].push(
            <button
                style={{
                    padding:'0 4px'
                }}
                onClick={
                    (e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        let ok = window.confirm('선택된 장비를 삭제하시겠습니까?')
                        if(!ok) return

                        let type = 'device'
                        let id = tempItemsList[idx][0]
                        deleteHandler({
                            type,
                            id
                        })
                    }
                }
            >DELETE</button>
        )
        itemsList[idx].push(
            <React.Fragment>
                선택<div style={{ display: 'inline-block', width: '8px' }}></div>
                <input
                    className="pointer"
                    type="checkbox"
                    checked={deviceList.deleteList[idx] ? true : false}
                    onChange={
                        (e) => {
                            if (e.target.checked) {
                                deviceList.deleteList[idx] = true
                            } else {
                                delete deviceList.deleteList[idx]
                            }
                            stateChangeHandler({deviceList})
                        }
                    }
                />
            </React.Fragment>
        )
    })
    let sn = deviceList.condition.sn
    let code = deviceList.condition.code
    let factory = deviceList.condition.factory
    let version = deviceList.condition.version
    return (
        <div style={{ width: '100%', display: 'inline-block', marginBottom: '18px' }}>
            <Title name={name} />
            <div style={{ color: 'rgba(80,80,80,0.8)', fontSize: '14px', fontWeight: '800', padding: '4px' }}>
                SN 검색
                <input
                    type="text"
                    style={{
                        marginLeft: '8px',
                        marginRight: '12px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: 'rgba(40,40,40,0.8)',
                        border: '1px solid rgba(80,80,80,0.8)'
                    }}
                    value={sn}
                    onChange={
                        (e) => {
                            let _sn = e.target.value
                            deviceSearchChangeHander({ sn : _sn })
                        }
                    }
                />
                장비 코드
                <input
                    type="text"
                    style={{
                        marginLeft: '8px',
                        marginRight: '12px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: 'rgba(40,40,40,0.8)',
                        border: '1px solid rgba(80,80,80,0.8)'
                    }}
                    value={code}
                    onChange={
                        (e) => {
                            let _code = e.target.value
                            deviceSearchChangeHander({ code: _code })
                        }
                    }
                />
                장비 버전
                <input
                    type="text"
                    style={{
                        marginLeft: '8px',
                        marginRight: '12px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: 'rgba(40,40,40,0.8)',
                        border: '1px solid rgba(80,80,80,0.8)'
                    }}
                    value={version}
                    onChange={
                        (e) => {
                            let _version = e.target.value
                            deviceSearchChangeHander({ version: _version })
                        }
                    }
                />
                공장 코드
                <input
                    type="text"
                    style={{
                        marginLeft: '8px',
                        marginRight: '12px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: 'rgba(40,40,40,0.8)',
                        border: '1px solid rgba(80,80,80,0.8)'
                    }}
                    value={factory}
                    onChange={
                        (e) => {
                            let _factory = e.target.value
                            deviceSearchChangeHander({ factory: _factory })
                        }
                    }
                />
                <input
                    className="pointer"
                    type="button"
                    style={{ width: '100px' }}
                    value="REFRESH"
                    onClick={
                        (e) => {
                            requestSearch()
                        }
                    }
                />
                <input 
                    className="pointer"
                    type='button'
                    style={{
                        width:'80px',
                        float:'right',
                        marginRight:'10px'
                    }}
                    value='선택삭제'
                    onClick={
                        () => {
                            let ok = window.confirm('선택된 장비를 모두 삭제하시겠습니까?')
                            if(!ok) return
                            deleteSelectAll()
                        }
                    }
                />
            </div>
            <Header
                columnNameList={columnNameList}
                LastColumn={
                    <React.Fragment>
                        전체선택<div style={{ display: 'inline-block', width: '8px' }}></div>
                        <input
                            className="pointer"
                            type="checkbox"
                            checked={Object.keys(deviceList.deleteList).length === itemsList.length ? true : false}
                            onChange={
                                (e) => {
                                    if(e.target.checked){
                                        itemsList.forEach((_, idx) => {
                                            deviceList.deleteList[idx] = true
                                        })
                                    }else{
                                        itemsList.forEach((_, idx) => {
                                            delete deviceList.deleteList[idx]
                                        })
                                    }
                                    stateChangeHandler({deviceList})
                                }
                            }
                        />
                    </React.Fragment>
                }
            />
            <Body
                itemsList={itemsList}
                stateChangeHandler={stateChangeHandler}
                deleteHandler={
                    (idx) => {
                        let type = 'device'
                        let id = tempItemsList[idx][0]
                        deleteHandler({
                            type,
                            id
                        })
                    }
                }
                deleteButtonUnuse={true}
                edit={{
                    available:true, 
                    index:6, 
                    editButtonOnClickHandler:(idx, editText) => {
                        if(typeof updateRequest === "function") updateRequest(tempItemsList[idx], editText)
                    }
                }}
            />
            <div
                style={{
                    width: 'calc(100% - 40px)',
                    height: '40px',
                    border: '1px solid rgba(80,80,80,0.8)',
                    position: 'fixed',
                    bottom: '20px',
                    borderRadius: '10px'
                }}
            >
                <div
                    style={{
                        width: '20%', float: 'left', height: '100%'
                    }}
                >

                </div>
                <div
                    style={{
                        width: '80%',
                        float: 'left',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        color: 'rgba(82,82,82,0.8)',
                        fontWeight: '400'
                    }}
                >
                    <div>
                        <input
                            style={{width:'60px', textAlign:'center'}}
                            type="number"
                            value={deviceList.tempPage}
                            onChange={
                                (e) => {
                                    let selectedPage = e.target.value
                                    currentPageChangeHandler(selectedPage)
                                    //pageChangeHandler(selectedPage)
                                }
                            }
                        />
                        <div style={{ display: 'inline-block', width: '12px' }}></div>
                        <button 
                            style={{width:'80px'}}
                            className="pointer"
                            onClick={
                                () => {
                                    if(typeof deviceList.tempPage === 'number'){
                                        pageChangeHandler(deviceList.tempPage)
                                    }else{
                                        alert('정확한 페이지를 입력해 주세요')
                                    }
                                }
                            }
                        >
                            이동
                        </button>
                        <div style={{ display: 'inline-block', width: '24px' }}></div>
                        현재페이지 {deviceList.currentPage}
                        <div style={{ display: 'inline-block', width: '8px' }}></div>
                        /
                        <div style={{ display: 'inline-block', width: '8px' }}></div>
                        전체 페이지 {Math.ceil(deviceList.totalSize / 10)}
                        <div style={{ display: 'inline-block', width: '8px' }}></div>
                        /
                        <div style={{ display: 'inline-block', width: '8px' }}></div>
                        등록 디바이스 개수 {deviceList.totalSize}</div>
                </div>
            </div>
        </div>
    )
}
export default DeviceList