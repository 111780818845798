import React from 'react'
import DeviceRegister from './device-register/index'
import ManagerAccount from './manager-account/index'
import DeviceList from './device-list/index'
import FirmwareList from './firmware-list'
import Log from './log'

let hostUrl = 'https://omorobot.infillenge.com'
let wsUrl = 'wss://omorobot.infillenge.com'
//let hostUrl = 'http://localhost:8000'
//let wsUrl = 'ws://localhost:8000'
let protocol = window.location.protocol
let hostname = window.location.hostname
let port = window.location.port
//let hostUrl = `${protocol}//${hostname}${port === '' ? '' : `:${8000}`}`
//let wsUrl = `${protocol.includes('https') ? 'wss' : 'ws'}://${hostname}${port === '' ? '' : `:${8000}`}`
let time = (new Date).getTime().toString()
let websocket = undefined
const defaultLeftTime = 60 * 10
console.log(time)
console.log(time.length)

const getFirmwareList = (callback) => {
    getData({ target: 'firmware' }, (result) => {
        let firmwareList = []
        console.log(result)
        result.forEach((rowData, index) => {
            let temp = []
            temp[0] = rowData.version
            temp[1] = rowData.description
            temp[2] = (rowData.active === 1 ? 'O' : 'X')
            temp[3] = rowData.deviceCode
            firmwareList.push(temp)
        })
        callback(firmwareList)
    })
}
const getDeviceList = (startIdx, condition, callback) => {
    if (startIdx < 0) startIdx = 0
    getData({ target: 'device', data: { pageStartIdx: startIdx, condition } }, (_) => {
        let deviceList = []
        let result = _.result
        let totalSize = _.totalSize
        console.log(_)
        result.forEach((rowData, index) => {
            let temp = []
            temp[0] = rowData.deviceUID
            temp[1] = rowData.uuid
            temp[2] = rowData.sn
            temp[3] = rowData.currentVersion
            temp[4] = rowData.updateDate
            temp[5] = rowData.updateCount
            temp[6] = rowData.tag
            deviceList.push(temp)
        })
        callback(deviceList, totalSize)
    })
}
const updateTag = ({ tag, sn, callback }) => {
    getData({ target: 'update', data: { list:"device", tag: tag, sn:sn } }, (_) => {
        callback(_)
    })
}
const getUserList = (callback) => {
    getData({ target: 'user' }, (result) => {
        let managerAccountItemsList = []
        result.forEach((rowData, index) => {
            let temp = []
            temp[0] = rowData.managerId
            temp[1] = rowData.managerPW
            temp[2] = rowData.factory
            temp[3] = rowData.deviceCode
            managerAccountItemsList.push(temp)
        })
        callback(managerAccountItemsList)
    })
}
const getData = ({ target, data }, callback) => {
    fetch(`${hostUrl}/system-information/${target}.json`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify((data ? data : {}))
    })
        .then((r) => { return r.json() })
        .then((data) => {
            callback(data)
        })
        .catch((e) => {
            console.log(e)
        })
}
const addUser = (userData, callback) => {

    fetch(`${hostUrl}/add-user`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(userData)
    })
        .then((r) => { return r.json() })
        .then((d) => {
            callback(d)
        })
        .catch((e) => {
            callback(e)
        })
}
const firmwareUpload = (firmwareData, callback) => {
    let file = new FormData()
    file.append('firmware', firmwareData.uploadFile)
    file.append('desc', firmwareData.description)
    file.append('deviceCode', firmwareData.deviceCode)
    fetch(`${hostUrl}/file-upload`, {
        method: 'POST',
        body: file
    })
        .then((r) => {
            return r.json()
        })
        .then((d) => {
            callback(d)
        })
        .catch((e) => {
            console.log(e)
        })
}
const getDeviceCode = (callback) => {
    fetch(`${hostUrl}/device-code`, {
        method: 'post',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ actionType: 'read' })
    })
        .then((r) => { return r.json() })
        .then((d) => {
            let deviceList = []
            for (let deviceCode in d) {
                let temp = [deviceCode, d[deviceCode]]
                deviceList.push(temp)
            }
            callback(deviceList)
        })
        .catch((e) => { console.log(e) })
}
const createDeviceCode = (deviceCodeInfo, callback) => {
    fetch(`${hostUrl}/device-code`, {
        method: 'post',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            actionType: 'create',
            code: deviceCodeInfo.code,
            description: deviceCodeInfo.description
        })
    })
        .then((r) => { return r.json() })
        .then((d) => {
            let deviceList = []
            for (let deviceCode in d) {
                let temp = [deviceCode, d[deviceCode]]
                deviceList.push(temp)
            }
            callback(deviceList)
        })
        .catch((e) => { console.log(e) })
}
const deleteData = (targetData, callback) => {
    let type = targetData.type
    let id = targetData.id
    fetch(`${hostUrl}/delete`, {
        method: 'post',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ type, id })
    })
        .then((r) => { return r.json() })
        .then((d) => {
            if (targetData.type === 'device') {
                console.log('delete result : ' + d.state)
                callback()
            } else if (targetData.type === 'user') {
                let managerAccountItemsList = []
                d.forEach((rowData, index) => {
                    let temp = []
                    temp[0] = rowData.managerId
                    temp[1] = rowData.managerPW
                    temp[2] = rowData.factory
                    temp[3] = rowData.deviceCode
                    managerAccountItemsList.push(temp)
                })
                callback(managerAccountItemsList)
            } else if (targetData.type === 'firmware') {
                let firmwareList = []
                d.forEach((rowData, index) => {
                    let temp = []
                    temp[0] = rowData.version
                    temp[1] = rowData.description
                    temp[2] = (rowData.active === 1 ? 'O' : 'X')
                    temp[3] = rowData.deviceCode
                    firmwareList.push(temp)
                })
                callback(firmwareList)
            } else {
                callback(d)
            }
        })
        .catch((e) => { console.log(e) })
}
const changeActiveVersion = ({ oldVersion, newVersion }, callback) => {
    fetch(`${hostUrl}/change-active-firmware`, {
        method: 'post',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ oldVersion, newVersion })
    })
        .then((r) => { return r.json() })
        .then((d) => { callback(d) })
        .catch((e) => { console.log(e) })
}
const requestLogin = ({ id, pw }, callback) => {
    fetch(`${hostUrl}/omorobot-admin-login`, {
        method: 'post',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            id, pw
        })
    })
        .then((r) => { return r.json() })
        .then((d) => { callback(undefined, d) })
        .catch((e) => { callback(e) })
}

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            timeLeft: 0,
            block: true,
            requestPending: false,
            requestProgressPercent: 0,
            loginSuccess: false,
            input_id: '',
            input_pw: '',
            currentPage: 'deviceRegister',
            deviceRegister: {
                title: '장비코드 등록',
                columnNameList: ['장비코드', '장비설명'],
                itemsList: [],
                temp: {
                    code: undefined,
                    description: undefined
                }
            },
            managerAccount: {
                title: '관리자 등록',
                columnNameList: ['아이디', '비밀번호', '공장', '장비종류', '삭제'],
                deviceCodeList: [],
                itemsList: [],
                temp: {
                    id: undefined,
                    password: undefined,
                    factory: undefined,
                    product: undefined
                }
            },
            deviceList: {
                title: '장비 목록',
                columnNameList: ['장비번호', 'UUID', 'SN', '버전', '업데이트 일자', '업데이트 횟수', '태그', 'DELETE'],
                itemsList: [],
                totalSize: 0,
                currentPage: 1,
                tempPage: 1,
                condition: {
                    code: '',
                    factory: '',
                    version: '',
                    sn : ''
                },
                deleteList: {}
            },
            firmwareList: {
                title: '펌웨어 목록',
                deviceCode: 'None',
                columnNameList: ['VERSION', 'DESCRIPTION', 'ACTIVE', 'DEVICE CODE', 'DELETE'],
                itemsList: [],
                temp: {
                    uploadFile: undefined,
                    description: undefined,
                    deviceCode: 'None',
                    filename: undefined
                }
            },
            log: {
                startDate: '',
                endDate: '',
                startTime: '00:00',
                endTime: '23:59',
                logData: []
            }
        }
        this.intervalWorkGroup = {}
        this.timer = setInterval(() => {
            for (let work in this.intervalWorkGroup) {
                this.intervalWorkGroup[work]()
            }
        }, 1000)
    }
    componentDidMount = () => {

        getUserList((userList) => {
            let managerAccount = this.getState(this.state.managerAccount)
            managerAccount.itemsList = userList
            getDeviceList(0, this.state.deviceList.condition, (dList, totalSize) => {
                let deviceList = this.getState(this.state.deviceList)
                deviceList.itemsList = dList
                deviceList.totalSize = totalSize
                getFirmwareList((fList) => {
                    let firmwareList = this.getState(this.state.firmwareList)
                    firmwareList.itemsList = fList
                    getDeviceCode((dcList) => {
                        let deviceRegister = this.getState(this.state.deviceRegister)
                        deviceRegister.itemsList = dcList
                        managerAccount.deviceCodeList = this.getDeviceCodeList(dcList)
                        this.setState({ managerAccount, deviceList, firmwareList, deviceRegister }, () => {
                            console.log(this.state)

                        })
                    })
                })
            })
        })
    }
    createDeviceCode = () => {
        createDeviceCode(this.state.deviceRegister.temp, (d) => {
            let deviceRegister = this.getState(this.state.deviceRegister)
            deviceRegister.itemsList = d
            this.setState({ deviceRegister })
        })
    }
    getDeviceCodeList = (deviceRegisterItemsList) => {
        let deviceCodeList = []
        deviceRegisterItemsList.forEach((codeGroup, idx) => {
            let deviceCode = codeGroup[0]
            deviceCodeList.push(deviceCode)
        })
        return deviceCodeList
    }
    deleteDeviceCode = (target) => {
        fetch(`${hostUrl}/device-code`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                actionType: 'delete',
                device_code: target[0],
                description: target[1]
            })
        })
            .then((r) => { return r.json() })
            .then((d) => {
                let deviceList = []
                for (let deviceCode in d) {
                    let temp = [deviceCode, d[deviceCode]]
                    deviceList.push(temp)
                }
                let deviceRegister = this.getState(this.state.deviceRegister)
                deviceRegister.itemsList = deviceList
                this.setState({ deviceRegister })
            })
            .catch((e) => { console.log(e) })
    }
    deleteHandler = (targetData, callback) => {
        console.log(targetData)
        deleteData(targetData, (d) => {
            if (targetData.type === 'device') {
                /*
                let deviceList = this.getState(this.state.deviceList)
                deviceList.itemsList = d
                this.setState({deviceList}, () => {
                    console.log(this.state)
                })
                */
                let currentPage = this.state.deviceList.currentPage
                let currentTotalSize = this.state.deviceList.totalSize - 1
                let lastPage = Math.ceil(currentTotalSize / 10)
                let startIdx = 0
                if (lastPage < currentPage) {
                    startIdx = (lastPage - 1) * 10
                    currentPage = lastPage
                } else {
                    startIdx = (currentPage - 1) * 10
                }
                getDeviceList(startIdx, this.state.deviceList.condition, (dList, totalSize) => {
                    console.log(dList)
                    let deviceList = this.getState(this.state.deviceList)
                    deviceList.itemsList = dList
                    deviceList.currentPage = currentPage
                    deviceList.totalSize = totalSize
                    this.setState({ deviceList }, () => {
                        console.log(this.state)

                    })
                })
            } else if (targetData.type === 'user') {
                let managerAccount = this.getState(this.state.managerAccount)
                managerAccount.itemsList = d
                this.setState({ managerAccount }, () => {
                    console.log(this.state)
                })
            } else if (targetData.type === 'firmware') {
                let firmwareList = this.getState(this.state.firmwareList)
                firmwareList.itemsList = d
                this.setState({ firmwareList }, () => {
                    console.log(this.state)
                })
            }
        })
    }
    addUser = () => {
        let managerAccount = JSON.parse(JSON.stringify(this.state.managerAccount))
        let userData = managerAccount.temp
        let id = userData.id
        let itemsList = managerAccount.itemsList
        let exist = false
        console.log(itemsList)
        itemsList.forEach((user, idx) => {
            if (user[0] === id) {
                exist = true
            }
        })
        if (exist) {
            alert('아이디가 중복 됩니다.')
            return
        }
        addUser(userData, (result) => {
            let managerAccountItemsList = []
            result.forEach((rowData, index) => {
                let temp = []
                temp[0] = rowData.managerId
                temp[1] = rowData.managerPW
                temp[2] = rowData.factory
                temp[3] = rowData.deviceCode
                managerAccountItemsList.push(temp)
            })
            let managerAccount = this.getState(this.state.managerAccount)
            managerAccount.itemsList = managerAccountItemsList
            this.setState({ managerAccount })
        })
    }
    firmwareUpload = () => {
        let firmwareTemp = this.state.firmwareList.temp

        if (!firmwareTemp.uploadFile || !firmwareTemp.description || firmwareTemp.description === '' || firmwareTemp.deviceCode === 'None') {
            alert('업로드 데이터를 올바르게 모두 입력해 주세요.')
            return
        }
        this.setState({ requestPending: true, block: true }, () => {
            firmwareUpload(this.state.firmwareList.temp, (result) => {

                if (result.state !== 'fail' && Array.isArray(result)) {
                    let fList = []
                    console.log(result)
                    result.forEach((rowData, index) => {
                        let temp = []
                        temp[0] = rowData.version
                        temp[1] = rowData.description
                        temp[2] = (rowData.active === 1 ? 'O' : 'X')
                        temp[3] = rowData.deviceCode
                        fList.push(temp)
                    })

                    let firmwareList = this.getState(this.state.firmwareList)
                    firmwareList.itemsList = fList
                    firmwareList.temp = {
                        uploadFile: undefined,
                        description: undefined,
                        deviceCode: 'None',
                        filename: undefined
                    }
                    this.setState({ firmwareList, block: false, requestPending: false }, () => {
                        console.log(this.state)
                    })
                } else {
                    this.setState({ block: false, requestPending: false }, () => {
                        setTimeout(() => {
                            alert('Upload Error : ' + result.message)
                        }, 100)
                    })
                }
            })
        })
    }
    changeActiveVersion = ({ oldVersion, newVersion }) => {
        changeActiveVersion({ oldVersion, newVersion }, (result) => {
            let fl = []
            result.forEach((rowData, index) => {
                let temp = []
                temp[0] = rowData.version
                temp[1] = rowData.description
                temp[2] = (rowData.active === 1 ? 'O' : 'X')
                temp[3] = rowData.deviceCode
                fl.push(temp)
            })
            let firmwareList = this.getState(this.state.firmwareList)
            firmwareList.itemsList = fl
            this.setState({ firmwareList }, () => {
                console.log(this.state)

            })
        })
    }
    getState = (state) => {
        return JSON.parse(JSON.stringify(state))
    }
    stateChangeHandler = (s) => {
        this.setState(s)
    }
    pageChangeHandler = (new_page) => {
        console.log(`new page : ${new_page}`)
        let startIdx = (Number(new_page) - 1) * 10
        getDeviceList(startIdx, this.state.deviceList.condition, (dList, totalSize) => {
            console.log(dList)
            let deviceList = this.getState(this.state.deviceList)
            deviceList.itemsList = dList
            deviceList.currentPage = new_page
            this.setState({ deviceList }, () => {
                console.log(this.state)

            })
        })
    }
    deviceSearchChangeHander = (new_search_condition) => {
        let deviceList = JSON.parse(JSON.stringify(this.state.deviceList))
        let condition = deviceList.condition
        for (let conditionType in new_search_condition) {
            condition[conditionType] = new_search_condition[conditionType]
        }
        this.setState({ deviceList })
    }
    requestSearch = () => {
        getDeviceList(0, this.state.deviceList.condition, (dList, totalSize) => {
            let deviceList = this.getState(this.state.deviceList)
            deviceList.itemsList = dList
            deviceList.totalSize = totalSize
            deviceList.currentPage = 1
            this.setState({ deviceList }, () => {
                console.log(this.state)
            })
        })
    }
    deleteSelectAll = () => {
        let deleteList = Object.keys(this.state.deviceList.deleteList)
        let numOfTarget = deleteList.length
        let deleteTargetIdList = []
        deleteList.forEach((deleteIndex, _) => {
            let targetId = this.state.deviceList.itemsList[deleteIndex][0]
            deleteTargetIdList.push(targetId)
        })
        console.log(deleteList)
        console.log(deleteTargetIdList)
        console.log(this.state.deviceList)
        let isPending = false
        this.setState({ block: true, requestPending: true }, () => {
            const deleteHandler = setInterval(() => {
                if (isPending) {
                    return
                }
                isPending = true
                let targetId = deleteTargetIdList.pop()
                console.log('delete start : ' + targetId)
                deleteData({ type: 'device', id: targetId }, (d) => {
                    console.log(d)
                    console.log('delete success : ' + targetId)
                    isPending = false
                })
                let requestProgressPercent = Math.floor((numOfTarget - deleteTargetIdList.length) * 100 / numOfTarget)
                this.setState({ requestProgressPercent })
                if (deleteTargetIdList.length <= 0) {
                    clearInterval(deleteHandler)
                    let startIdx = (this.state.deviceList.currentPage - 1) * 10
                    let totalSize = this.state.deviceList.totalSize - numOfTarget
                    let lastIndex = totalSize - 1
                    if (startIdx > lastIndex) {
                        startIdx = Math.floor(lastIndex / 10) * 10
                    }
                    let currentPage = startIdx / 10 + 1
                    getDeviceList(startIdx, this.state.deviceList.condition, (dList, totalSize) => {
                        let deviceList = this.getState(this.state.deviceList)
                        deviceList.itemsList = dList
                        deviceList.totalSize = totalSize
                        deviceList.deleteList = {}
                        deviceList.currentPage = currentPage
                        this.setState({ deviceList, requestPending: false, block: false, requestProgressPercent: 0 }, () => {
                            console.log(this.state)
                        })
                    })
                }
            }, 400)
        })



    }
    timeCount = () => {
        let timeLeft = this.state.timeLeft - 1
        if (timeLeft <= 0) {
            delete this.intervalWorkGroup['sessionValidTimer']
            console.log('delete session valid timer')
            console.log(this.intervalWorkGroup)
            console.log('websocket session close')
            websocket.close()
            websocket = undefined
            this.setState({ block: true }, () => {
                setTimeout(() => {
                    alert('유효시간을 초과하였습니다. 접속을 종료합니다.')
                }, 200)
            })

            return
        } else {
            this.setState({ timeLeft })
        }
    }
    timeChangeHandler = (new_time) => {
        let log = JSON.parse(JSON.stringify(this.state.log))
        for (let prop in new_time) {
            log[prop] = new_time[prop]
        }
        this.setState({ log }, () => {
            console.log(this.state.log)
        })
    }
    getLogData = () => {
        let start = `${this.state.log.startDate}T00:00:00.000000000Z`
        let end = `${this.state.log.endDate}T23:59:59.999999999Z`
        fetch(`${hostUrl}/get-log`, {
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                queryString: `select * from log where time >= '${start}' and time <= '${end}' order by time desc`
            })
        })
            .then((res) => { return res.json() })
            .then((data) => {
                console.log(data)
                let log = JSON.parse(JSON.stringify(this.state.log))
                log.logData = data.result.result
                this.setState({ log }, () => { console.log(this.state) })
            })
            .catch((error) => { console.log(error) })
    }
    currentPageChangeHandler = (new_page) => {
        let deviceList = JSON.parse(JSON.stringify(this.state.deviceList))
        let totalSize = deviceList.totalSize
        let maxPage = Math.ceil(totalSize / 10)
        if (new_page !== '' && Number(new_page) != NaN) {
            new_page = Number(new_page)
            if (new_page <= 0 || new_page > maxPage) {
                return
            } else {
                deviceList.tempPage = new_page
                this.setState({ deviceList })
            }
        } else if (new_page === '') {
            deviceList.tempPage = new_page
            this.setState({ deviceList })
        } else {
            console.log('어디에도 포함 되지 않습니다.')
        }
    }
    render = () => {
        let minutes = Math.floor(this.state.timeLeft / 60)
        let seconds = this.state.timeLeft % 60
        seconds = (seconds < 10 ? `0${seconds}` : seconds)
        return (
            <div style={{
                width: '100vw',
                height: '100vh',
                padding: this.state.loginSuccess ? '20px' : '0px',
                minWidth: '680px',
                minHeight: '400px',
                overflow: 'auto'
            }}>
                {
                    this.state.block === false ?
                        <React.Fragment>
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px'
                                }}
                            >
                                <div
                                    style={{
                                        float: 'right',
                                        fontSize: '12px',
                                        paddingRight: '10px',
                                    }}
                                >
                                    <div style={{ width: '120px', display: 'inline-block', color: this.state.timeLeft <= 180 ? 'rgba(200,100,100,1)' : 'rgba(100,100,100,1)' }}>
                                        <div style={{ width: '60px', display: 'inline-block' }}>남은 시간 : </div>
                                        {
                                            minutes === 0 ? '' :
                                                <React.Fragment>
                                                    <div style={{ width: '15px', display: 'inline-block' }}>{minutes}</div>
                                                    <div style={{ width: '15px', display: 'inline-block' }}>분</div>
                                                </React.Fragment>
                                        }
                                        <div style={{ width: '15px', display: 'inline-block' }}>{seconds}</div>초
                                    </div>
                                    <div
                                        className="pointer"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '8px',
                                            width: '30px',
                                            borderRadius: '5px',
                                            border: '1px solid rgba(100,100,100,1)',
                                            textAlign: 'center',
                                            margin: '0 4px 4px 12px',
                                            color: this.state.timeLeft <= 180 ? 'rgba(200,100,100,1)' : 'rgba(100,100,100,1)'
                                        }}
                                        onClick={
                                            (e) => {
                                                if (websocket || websocket.OPEN) {
                                                    websocket.close()
                                                    websocket = undefined
                                                }
                                                setTimeout(() => {
                                                    websocket = new WebSocket(wsUrl)
                                                    websocket.onopen = () => {
                                                        console.log('open')
                                                        this.setState({ timeLeft: defaultLeftTime }, () => {
                                                            this.intervalWorkGroup['sessionValidTimer'] = this.timeCount
                                                        })
                                                    }
                                                    websocket.onclose = () => {
                                                        websocket = undefined
                                                    }
                                                    websocket.onmessage = (e) => {
                                                        console.log(e)
                                                        let message = JSON.parse(e.data).message
                                                        if (message === 'new session') {
                                                            websocket.close()
                                                            this.setState({ block: true }, () => {
                                                                setTimeout(() => {
                                                                    alert('새로운 접속이 이루어졌습니다. 현재 접속을 종료합니다.')
                                                                }, 200)
                                                            })
                                                        }
                                                    }
                                                }, 100)
                                            }
                                        }
                                    >연장</div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    backgroundColor: 'rgba(80,80,80,0.4)',
                                    marginBottom: '40px',
                                    borderRadius: '10px',
                                    overflow: 'hidden'
                                }}
                            >
                                <div
                                    className="navi"
                                    style={{
                                        width: '20%',
                                        height: '100%',
                                        float: 'left',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        className="pointer"
                                        onClick={
                                            (e) => {
                                                this.setState({ currentPage: 'deviceRegister' })
                                            }
                                        }
                                    >
                                        장비등록
                                    </div>
                                </div>
                                <div
                                    className="navi"
                                    style={{
                                        width: '20%',
                                        height: '100%',
                                        float: 'left',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        className="pointer"
                                        onClick={
                                            (e) => {
                                                this.setState({ currentPage: 'managerAccount' })
                                            }
                                        }
                                    >
                                        관리자 등록
                                    </div>
                                </div>
                                <div
                                    className="navi"
                                    style={{
                                        width: '20%',
                                        height: '100%',
                                        float: 'left',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        className="pointer"
                                        onClick={
                                            (e) => {
                                                this.setState({ currentPage: 'deviceList' })
                                            }
                                        }
                                    >
                                        장비 목록
                                    </div>
                                </div>
                                <div
                                    className="navi"
                                    style={{
                                        width: '20%',
                                        height: '100%',
                                        float: 'left',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        className="pointer"
                                        onClick={
                                            (e) => {
                                                this.setState({ currentPage: 'firmwareList' })
                                            }
                                        }
                                    >
                                        펌웨어 목록
                                    </div>
                                </div>
                                <div
                                    className="navi"
                                    style={{
                                        width: '20%',
                                        height: '100%',
                                        float: 'left',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        className="pointer"
                                        onClick={
                                            (e) => {
                                                this.setState({ currentPage: 'log' })
                                            }
                                        }
                                    >
                                        로그 보기
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.currentPage === 'deviceRegister' ?
                                    <DeviceRegister
                                        deviceRegister={this.getState(this.state.deviceRegister)}
                                        stateChangeHandler={this.stateChangeHandler}
                                        createDeviceCode={this.createDeviceCode}
                                        deleteDeviceCode={this.deleteDeviceCode}
                                    />
                                    :
                                    undefined
                            }
                            {
                                this.state.currentPage === 'managerAccount' ?
                                    <ManagerAccount
                                        deviceCodeList={this.state.deviceRegister.itemsList}
                                        managerAccount={this.getState(this.state.managerAccount)}
                                        stateChangeHandler={this.stateChangeHandler}
                                        addUser={this.addUser}
                                        deleteHandler={this.deleteHandler}
                                    />
                                    :
                                    undefined
                            }
                            {
                                this.state.currentPage === 'deviceList' ?
                                    <DeviceList
                                        deviceList={this.getState(this.state.deviceList)}
                                        stateChangeHandler={this.stateChangeHandler}
                                        deleteHandler={this.deleteHandler}
                                        pageChangeHandler={this.pageChangeHandler}
                                        deviceSearchChangeHander={this.deviceSearchChangeHander}
                                        requestSearch={this.requestSearch}
                                        deleteSelectAll={this.deleteSelectAll}
                                        currentPageChangeHandler={this.currentPageChangeHandler}
                                        updateRequest={(target, tag) => {
                                            console.log(target)
                                            console.log(tag)
                                            console.log("SN", target[2])
                                            updateTag({
                                                tag:tag,
                                                sn:target[2],
                                                callback:(res) => {
                                                    if(res.result === "success"){
                                                        let startIdx = (this.state.deviceList.currentPage - 1) * 10
                                                        getDeviceList(startIdx, this.state.deviceList.condition, (dList, totalSize) => {
                                                            console.log(dList)
                                                            let deviceList = this.getState(this.state.deviceList)
                                                            deviceList.itemsList = dList
                                                            deviceList.currentPage = this.state.deviceList.currentPage
                                                            deviceList.totalSize = totalSize
                                                            this.setState({ deviceList }, () => {
                                                                console.log(this.state)
                                        
                                                            })
                                                        })
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                    :
                                    undefined
                            }
                            {
                                this.state.currentPage === 'firmwareList' ?
                                    <FirmwareList
                                        deviceCodeList={this.state.deviceRegister.itemsList}
                                        deviceCode={this.state.firmwareList.deviceCode}
                                        firmwareList={this.state.firmwareList}
                                        stateChangeHandler={this.stateChangeHandler}
                                        firmwareUpload={this.firmwareUpload}
                                        deleteHandler={this.deleteHandler}
                                        changeActiveVersion={this.changeActiveVersion}
                                    />
                                    :
                                    undefined
                            }
                            {
                                this.state.currentPage === 'log' ?
                                    <Log
                                        startDate={this.state.log.startDate}
                                        endDate={this.state.log.endDate}
                                        startTime={this.state.log.startTime}
                                        endTime={this.state.log.endTime}
                                        timeChangeHandler={this.timeChangeHandler}
                                        getLogData={this.getLogData}
                                        logData={this.state.log.logData}
                                    />
                                    :
                                    undefined
                            }
                        </React.Fragment>
                        :
                        (
                            this.state.loginSuccess === false ?
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection:'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundImage: 'url("/img/oagv-main-bg.jpg")',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}>
                                    <div style={{
                                        width: '600px', height: '200px', marginBottom:'80px',borderRadius:'20px',overflow:'hidden',opacity:'0.8'

                                    }}>
                                        <img src="/img/omorobot-main-name.png" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <div style={{
                                        fontSize: '14px',
                                        color: 'rgba(80,80,80,0.4)',
                                        backgroundColor: 'rgba(255,255,255,0.4)',
                                        padding: '20px',
                                        borderRadius: '20px',
                                        backdropFilter: 'blur(5px)'
                                    }}>
                                        <div style={{ padding: '8px' }}>
                                            <input
                                                type="text"
                                                placeholder="아이디 입력"
                                                style={{ textAlign: 'center', width: '250px', height: '30px', borderRadius: '5px', borderWidth: '0' }}
                                                value={this.state.input_id}
                                                onChange={
                                                    (e) => {
                                                        let input_id = e.target.value
                                                        this.setState({ input_id })
                                                    }
                                                }
                                            />
                                        </div>
                                        <div style={{ padding: '8px' }}>
                                            <input
                                                type="password"
                                                placeholder="비밀번호 입력"
                                                style={{ textAlign: 'center', width: '250px', height: '30px', borderRadius: '5px', borderWidth: '0' }}
                                                value={this.state.input_pw}
                                                onChange={
                                                    (e) => {
                                                        let input_pw = e.target.value
                                                        this.setState({ input_pw })
                                                    }
                                                }
                                            />
                                        </div>
                                        <div style={{ padding: '8px', display: 'flex', justifyContent: 'center' }}>
                                            <input
                                                className="pointer"
                                                type="button"
                                                placeholder="로그인"
                                                style={{ textAlign: 'center', width: '150px', height: '30px', borderRadius: '5px', borderWidth: '0' }}
                                                value="LOG IN"
                                                onClick={
                                                    (e) => {
                                                        requestLogin({
                                                            id: this.state.input_id,
                                                            pw: this.state.input_pw
                                                        }, (err, result) => {
                                                            if (err) {
                                                                alert('아이디 또는 비밀 번호가 일치하지 않습니다.')
                                                                this.setState({ input_id: '', input_pw: '' })
                                                            } else {
                                                                this.setState({ loginSuccess: true, block: false }, () => {
                                                                    websocket = new WebSocket(wsUrl)
                                                                    websocket.onopen = () => {
                                                                        console.log('open')
                                                                        this.setState({ timeLeft: defaultLeftTime }, () => {
                                                                            this.intervalWorkGroup['sessionValidTimer'] = this.timeCount
                                                                        })
                                                                    }
                                                                    websocket.onclose = () => {
                                                                        websocket = undefined
                                                                    }
                                                                    websocket.onmessage = (e) => {
                                                                        console.log(e)
                                                                        let message = JSON.parse(e.data).message
                                                                        if (message === 'new session') {
                                                                            websocket.close()
                                                                            this.setState({ block: true }, () => {
                                                                                setTimeout(() => {
                                                                                    alert('새로운 접속이 이루어졌습니다. 현재 접속을 종료합니다.')
                                                                                }, 200)
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        /*
                                                        if (this.state.input_pw === this.state.auth_pw && this.state.auth_id === this.state.input_id) {
                                                            this.setState({ loginSuccess: true, block: false }, () => {
                                                                websocket = new WebSocket(wsUrl)
                                                                websocket.onopen = () => {
                                                                    console.log('open')
                                                                    this.setState({ timeLeft: defaultLeftTime }, () => {
                                                                        this.intervalWorkGroup['sessionValidTimer'] = this.timeCount
                                                                    })
                                                                }
                                                                websocket.onclose = () => {
                                                                    websocket = undefined
                                                                }
                                                                websocket.onmessage = (e) => {
                                                                    console.log(e)
                                                                    let message = JSON.parse(e.data).message
                                                                    if (message === 'new session') {
                                                                        websocket.close()
                                                                        this.setState({ block: true }, () => {
                                                                            setTimeout(() => {
                                                                                alert('새로운 접속이 이루어졌습니다. 현재 접속을 종료합니다.')
                                                                            }, 200)
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        } else {
                                                            alert('아이디 또는 비밀 번호가 일치하지 않습니다.')
                                                            this.setState({ input_id: '', input_pw: '' })
                                                        }
                                                        */
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                (
                                    this.state.requestPending ?
                                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ fontSize: '30px', color: 'rgba(80,80,80,0.4)' }}>Waiting Response From Server. <div style={{ display: 'inline-block', fontWeight: '1200', color: 'rgba(0,0,0,0.2)' }}>{this.state.requestProgressPercent}%</div></div>
                                        </div>
                                        :
                                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ fontSize: '30px', color: 'rgba(80,80,80,0.4)' }}>GOOD BYE!</div>
                                        </div>
                                )
                        )
                }
            </div>
        )
    }
}

export default App