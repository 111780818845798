import React, { useState } from 'react'
import { TbEditCircle } from "react-icons/tb"
import { GiConfirmed } from "react-icons/gi"
import { FcCancel } from "react-icons/fc"

const BodyColumn = ({ ratio, item }) => {
    return (
        <div style={{ width: `${ratio}%`, float: 'left', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px' }}>
            <div>{item}</div>
        </div>
    )
}
const EditableBodyColumn = ({ ratio, item, editButtonOnClickHandler }) => {
    const [onEdit, setOnEdit] = useState(false)
    const [editText, setEditText] = useState(item)
    return (
        <div style={{ width: `${ratio}%`, float: 'left', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px' }}>
            {
                onEdit
                    ?
                    <>
                        <input
                            style={{ width: "calc(100% - 40px)", height: "20px", fontSize: "10px" }}
                            value={editText}
                            onChange={
                                (e) => {
                                    setEditText(e.target.value)
                                }
                            }
                        />
                        <div
                            className='edit-cancel-button' style={{ width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: 'center', cursor: "pointer", borderRadius: "2px" }}
                            onClick={
                                () => {
                                    setEditText(item)
                                    setOnEdit(false)
                                }
                            }
                        >
                            <FcCancel />
                        </div>
                        <div
                            className='edit-confirm-button' style={{ width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: 'center', cursor: "pointer", borderRadius: "2px" }}
                            onClick={
                                () => {
                                    if (typeof editButtonOnClickHandler === "function") {
                                        editButtonOnClickHandler(editText)
                                        setOnEdit(false)
                                    }
                                }
                            }
                        >
                            <GiConfirmed />
                        </div>
                    </>
                    :
                    <>
                        <div style={{ width: "calc(100% - 20px)", display: "flex", justifyContent: "center", alignItems: 'center' }}>{item}</div>
                        <div
                            className='edit-button' style={{ width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: 'center', cursor: "pointer", borderRadius: "2px" }}
                            onClick={
                                () => {
                                    setOnEdit(true)
                                }
                            }
                        >
                            <TbEditCircle />
                        </div>
                    </>
            }
        </div>
    )
}
const BodyRow = ({ items, deleteHandler, style, onRowClick, rowNumber, deleteButtonUnuse, edit = { available: false, index: 0, editButtonOnClickHandler: () => { } } }) => {

    let bodyItemColumn = []
    let ratio = (100 / (deleteButtonUnuse ? items.length : (items.length + 1))).toFixed(4)
    items.forEach((item, idx) => {
        bodyItemColumn.push(
            edit.available && edit.index === idx
                ?
                <EditableBodyColumn key={idx} ratio={ratio} item={item} editButtonOnClickHandler={(editText) => { if (typeof edit.editButtonOnClickHandler === "function") edit.editButtonOnClickHandler(editText) }} />
                :
                <BodyColumn key={idx} ratio={ratio} item={item} />
        )
    })
    if (!deleteButtonUnuse) {
        bodyItemColumn.push(
            <BodyColumn key={items.length} ratio={ratio} item={(
                <button
                    style={{
                        padding: '0 4px'
                    }}
                    onClick={
                        (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            deleteHandler()
                        }
                    }
                >DELETE</button>
            )} />
        )
    }
    return (
        <div className="body-row" onClick={() => { onRowClick() }} style={style ? style : { width: '100%', height: '40px', color: 'rgba(255,255,255,1)' }}>
            {bodyItemColumn}
        </div>
    )
}

const Body = ({ itemsList, deleteHandler, rowStyleList, onRowClick, deleteButtonUnuse, edit = { available: false, index: 0, editButtonOnClickHandler: () => { } } }) => {
    let defaultStyle = {
        width: '100%',
        display: 'inline-block',
        maxHeight: 'calc(100vh - 320px)',
        backgroundColor: 'rgba(180,180,180,1)',
        color: 'rgba(255,255,255,1)',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
    let bodyItemRows = []
    itemsList.forEach((items, idx) => {
        let style = undefined
        if (rowStyleList) style = rowStyleList[idx]
        bodyItemRows.push(
            <BodyRow
                key={items[0]}
                items={items}
                deleteHandler={
                    () => {
                        deleteHandler(idx)
                    }
                }
                style={style}
                onRowClick={
                    () => {
                        if (onRowClick) onRowClick(idx)
                    }
                }
                deleteButtonUnuse={deleteButtonUnuse}
                edit={edit ? { available:edit.available, index:edit.index, editButtonOnClickHandler:(editText) => { if(typeof edit.editButtonOnClickHandler === "function") edit.editButtonOnClickHandler(idx, editText) } } : {}}
            />
        )
    })
    return (
        <div className="body" style={defaultStyle}>
            {bodyItemRows}
        </div>
    )
}

export default Body