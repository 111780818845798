import React from 'react'

const Row = ({row}) => {
    let color = row.result.includes('실패') ? 'rgba(200,40,40,0.8)' : 'rgba(20,20,20,0.8)'
    return (
        <div style={{width:'100%', fontSize:'12px', display:'inline-block',height:'20px'}}>
            {
                row.time ? 
                <div style={{float:'left', width:'200px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.time}</div>
                </div> 
                : undefined
            }
            {
                row.who ? 
                <div style={{float:'left', width:'40px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.who}</div>
                </div> 
                : undefined
            }
            {
                row.id ? 
                <div style={{float:'left', width:'120px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.id}</div>
                </div> 
                : undefined
            }
            {
                row.sn ? 
                <div style={{float:'left', width:'120px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.sn}</div>
                </div> 
                : undefined
            }
            {
                row.deviceId ? 
                <div style={{float:'left', width:'180px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.deviceId}</div>
                </div> 
                : undefined
            }
            {/** ==================== right column ================== */}
            {
                row.result ? 
                <div style={{float:'right', width:'300px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.result}</div>
                </div> 
                : undefined
            }
            {
                row.action ? 
                <div style={{float:'right', width:'200px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', color}}>
                    <div>{row.action}</div>
                </div> 
                : undefined
            }
        </div>
    )
}

const Log = ({startDate,endDate,startTime,endTime,timeChangeHandler,getLogData,logData}) => {
    let rows = []
    
    logData.forEach((row, idx) => {
        rows.push(<Row key={idx} row={row}/>)
    })


    return (
        <div style={{ width: '100%', display: 'inline-block', marginBottom: '18px' }}>
            <div style={{width:'100%', height:'60px', borderBottom:'1px solid rgba(0,0,0,0.2)'}}>
                <div style={{display:'inline-block', width:'20px'}}></div>
                로그 검색 시작
                <div style={{display:'inline-block', width:'10px'}}></div>
                <input 
                    type="date" 
                    value={startDate}
                    max={endDate}
                    onChange={
                        (e) => {
                            timeChangeHandler({startDate:e.target.value})
                        }
                    }
                />
                <div style={{display:'inline-block', width:'10px'}}></div>
                {/*
                <input 
                    type="time"
                    value={startTime}
                    onChange={
                        (e) => {
                            timeChangeHandler({startTime:e.target.value})
                        }
                    }
                />
                */}
                <div style={{display:'inline-block', width:'40px'}}></div>
                로그 검색 종료
                <div style={{display:'inline-block', width:'10px'}}></div>
                <input 
                    type="date" 
                    value={endDate}
                    min={startDate}
                    onChange={
                        (e) => {
                            timeChangeHandler({endDate:e.target.value})
                        }
                    }
                />
                <div style={{display:'inline-block', width:'10px'}}></div>
                <div style={{display:'inline-block', fontSize:'12px'}}>
                    (일자는 UTC 기준입니다. 조회 기간은 최대 7일입니다.)
                </div>
                
                {/*
                <input 
                    type="time"
                    value={endTime}
                    onChange={
                        (e) => {
                            timeChangeHandler({endTime:e.target.value})
                        }
                    }
                />
                */}
                <input 
                    className="pointer"
                    type="button" 
                    value="검색" 
                    style={{
                        width:'60px', 
                        marginLeft:'60px'
                    }} 
                    onClick={
                        () => {
                            if(!startDate || !startTime || !endDate || !endTime){
                                console.log('incorrect values')
                            }else{
                                console.log('correct values')
                                let sd = new Date(startDate)
                                let ed = new Date(endDate)
                                let max_date_diff = 6 * 24 * 60 * 60 * 1000
                                if(max_date_diff < (ed - sd)){
                                    alert('검색 기간은 최대 7일 까지 입니다.')
                                }else{
                                    getLogData()
                                }
                            }
                        }
                    }
                />
            </div>
            <div 
                style={{
                    width:'100%', 
                    height:'calc(100vh - 300px)', 
                    border:'1px solid rgba(0,0,0,0.2)', 
                    overflowY:'auto'
                }}
            >
                {rows}
            </div>
        </div>
    )
}

export default Log