import React from 'react'
import Title from '../title/index'
import Header from '../table-header/index'
import Body from '../table-body/index'

const DeviceRegister = ({deviceRegister, stateChangeHandler, createDeviceCode, deleteDeviceCode}) => {
    let name = deviceRegister.title
    let columnNameList = deviceRegister.columnNameList
    let itemsList = deviceRegister.itemsList
    let code = deviceRegister.temp.code
    let description = deviceRegister.temp.description
    return (
        <div style={{width:'100%', display:'inline-block',marginBottom:'18px'}}>
            <Title name={name}/>
            <Header columnNameList={columnNameList}/>
            <Body 
            itemsList={itemsList} 
            stateChangeHandler={stateChangeHandler}
            deleteHandler={
                (idx) => {
                    deleteDeviceCode(itemsList[idx])
                }
            }
            deleteButtonUnuse={true}
            />
            <div
                style={{
                    width:'calc(100% - 40px)',
                    height:'40px',
                    border:'1px solid rgba(80,80,80,0.8)',
                    position:'fixed',
                    bottom:'20px',
                    borderRadius:'10px'
                }}
            >
                <div style={{width:'33.3333%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <input 
                        type="text" 
                        placeholder="코드를 입력하세요" 
                        style={{padding:'4px'}}
                        value={code || ''}
                        onChange={
                            (e) => {
                                let new_code = e.target.value
                                console.log(new_code)
                                var regType1 = /^[A-Za-z0-9+]{0,2}$/; 
                                if(!regType1.test(new_code)){
                                    console.log('정규식 에러')
                                    return
                                }
                                if(new_code.length > 2){
                                    return
                                }else{
                                    deviceRegister.temp.code = new_code
                                    stateChangeHandler({deviceRegister})
                                }
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'33.3333%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <input 
                        type="text" 
                        placeholder="설명을 입력하세요" 
                        style={{padding:'4px'}}
                        value={description || ''}
                        onChange={
                            (e) => {
                                let new_description = e.target.value
                                var regType1 = /^[A-Za-z0-9+]{0,20}$/; 
                                if(!regType1.test(new_description)){
                                    return
                                }
                                deviceRegister.temp.description = new_description
                                stateChangeHandler({deviceRegister})
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'33.3333%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <button
                            style={{
                                padding:'0px 8px'
                            }}
                            onClick={
                                () => {
                                    //console.log(`code length : ${code.length},  description length : ${description.length}`)
                                    if(!code || code.length === '' || code.length !== 2){
                                        alert('코드는 반드시 2자리 여야 합니다.')
                                    }else if(!description || description === ''){
                                        console.log('설명을 입력하세요')
                                    }else if(description.length > 20){
                                        alert('설명은 영문 20자 이내여야 합니다.')
                                    }else{
                                        createDeviceCode()
                                    }
                                }
                            }
                        >
                            추가
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceRegister