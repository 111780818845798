import React from 'react'
import Title from '../title/index'
import Header from '../table-header/index'
import Body from '../table-body/index'

const deviceCodeOptions = (codeList) => {
    let options = []
    codeList.forEach((code, index) => {
        options.push(
            <option
                value={code[0]}
                key={index}
            >
                {code[0]}
            </option>
        )
    })
    return options
} 

const ManagerAccount = ({managerAccount, stateChangeHandler, addUser, deleteHandler, deviceCodeList}) => {
    let name = managerAccount.title
    let columnNameList = managerAccount.columnNameList
    let itemsList = managerAccount.itemsList
    let id = managerAccount.temp.id
    let password = managerAccount.temp.password
    let factory = managerAccount.temp.factory
    let product = managerAccount.temp.product
    return (
        <div style={{width:'100%', display:'inline-block', marginBottom:'20px'}}>
            <Title name={name}/>
            <Header columnNameList={columnNameList}/>
            <Body 
            itemsList={itemsList} 
            stateChangeHandler={stateChangeHandler}
            deleteHandler={
                (idx) => {
                    let positive_answer = window.confirm(`매니저 ${itemsList[idx][0]}를 삭제하시겠습니까?`)
                    if(!positive_answer) return
                    deleteHandler({
                        type : 'user',
                        id : itemsList[idx][0]
                    })
                }
            }
            />
            <div
                style={{
                    width:'calc(100% - 40px)',
                    height:'40px',
                    border:'1px solid rgba(80,80,80,0.8)',
                    position:'fixed',
                    bottom:'20px',
                    borderRadius:'10px'
                }}
            >
                <div style={{width:'20%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <input 
                        type="text" 
                        placeholder="아이디를 입력하세요" 
                        style={{padding:'4px'}}
                        value={id || ''}
                        onChange={
                            (e) => {
                                let new_id = e.target.value
                                var regType1 = /^[A-Za-z0-9+]{0,20}$/; 
                                if(!regType1.test(new_id)){
                                    alert('아이디는 영문 대소문자 및 숫자로 이루어 지며 20자 이내여야 합니다.')
                                    return
                                }
                                managerAccount.temp.id = new_id
                                stateChangeHandler({managerAccount})
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'20%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <input 
                        type="text" 
                        placeholder="비밀번호를 입력하세요" 
                        style={{padding:'4px'}}
                        value={password || ''}
                        onChange={
                            (e) => {
                                let new_pw = e.target.value
                                var regType1 = /^[A-Za-z0-9+]{0,20}$/; 
                                if(!regType1.test(new_pw)){
                                    alert('비밀번호는 영문 대소문자 및 숫자로 이루어 지며 20자 이내여야 합니다.')
                                    return
                                }
                                managerAccount.temp.password = new_pw
                                stateChangeHandler({managerAccount})
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'20%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <input 
                        type="text" 
                        placeholder="공장 코드 입력하세요" 
                        style={{padding:'4px'}}
                        value={factory || ''}
                        onChange={
                            (e) => {
                                let new_factory = e.target.value
                                var regType1 = /^[A-Za-z0-9+]{0,2}$/; 
                                if(!regType1.test(new_factory)){
                                    alert('공장 코드는 2글자 영문 대소문자 및 숫자로 이루어집니다.')
                                    return
                                }
                                managerAccount.temp.factory = new_factory
                                stateChangeHandler({managerAccount})
                            }
                        }
                        />
                    </div>
                </div>
                <div style={{width:'20%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <select
                            value={product || 'None'}
                            onChange={
                                (e) => {
                                    let product = e.target.value
                                    managerAccount.temp.product = product
                                    stateChangeHandler({managerAccount})
                                }
                            }
                        >
                            <option value="None">None</option>
                            {deviceCodeOptions(deviceCodeList)}
                        </select>
                    </div>
                </div>
                <div style={{width:'20%', height:'100%',float:'left', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <button
                            style={{
                                padding:'0px 8px'
                            }}
                            onClick={
                                () => {
                                    if(!factory || factory === '' || factory.length !== 2){
                                        alert('공장 코드는 2글자 영문 대소문자 및 숫자 조합입니다.')
                                        return
                                    }else if(!product || product === '' || product === 'None'){
                                        alert('장비코드가 선택되지 않았습니다.')
                                        return
                                    }else if(!id || id === '' || !password || password === '') {
                                        alert('비밀번호 또는 아이디가 없습니다.')
                                        return
                                    }
                                    addUser()
                                }
                            }
                        >
                            추가
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagerAccount