import React from 'react'

const Title = ({name}) => {
    return (
        <div style={{
            fontSize:'18px',
            color:'rgba(80,80,80,0.8)',
            fontWeight:'600'
        }}>
            {name}
        </div>
    )
}

export default Title